import { appState } from '../AppState';
import { FieldPicker } from '../chart/FieldPicker'

export class XAxisFieldPicker extends FieldPicker {
  componentDidMount() {
    appState.structureChangeNotifier.subscribe(() => {

      // reset if data structure changes
      this.setState({
        chosenPath: appState.state.xAxisFieldPath
      });
    });
  }
}
