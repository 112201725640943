import React, { Component } from 'react'
import { appState, ChartState } from './AppState'
import { Debouncer } from './Debouncer';

import firebase from 'firebase/app'

export class JsonInputSection extends Component<any, {
  text: string;
  isValid: boolean | null;
}> {

  exampleJson: string = '[{"id":1,"value":2,"name":"John","salary": 3700},{"id":2,"value":1,"name":"Sally","salary":2900},{"id":3,"value":5,"name":"Nick","salary":1800},{"id":4,"value":7,"name":"Anna","salary":4800}]';
  exampleState: ChartState = {
    xAxisFieldPath: '."name"',
    valuesFieldPaths: ['."salary"']
  };

  debouncer: Debouncer;
  textArea?: HTMLTextAreaElement | null;

  constructor(props: any) {
    super(props);

    this.debouncer = new Debouncer(1000);
    this.state = {
      text: '',
      isValid: null
    };
  }

  componentDidMount() {
    this.textArea?.focus();
  }

  changeValue(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const inputText = e.target.value;

    this.setState({
      text: inputText,
      isValid: null
    });

    this.debouncer.run(() => {
      this.validateAndSet(inputText);
    });
  }

  validateAndSet(text: string) {
    const isValid = appState.setJson(text);

    this.setState({
      isValid: isValid
    });
  }

  loadExample() {
    this.setState({
      isValid: null,
      text: ''
    });

    const isValid = appState.loadExample(this.exampleJson, this.exampleState);

    this.setState({
      text: this.exampleJson,
      isValid: isValid
    });

    firebase.analytics().logEvent('example_loaded');
  }

  canProceedToChart() {
    return this.state.isValid && this.state.text.length > 0;
  }

  render() {
    return (
      <section id="hero" className="d-flex align-items-center">

        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 d-flex flex-column">
              <h1>Prepare your data</h1>
              <h2>
                Paste your JSON data into the box to begin. Data will be validated and processed as you edit the text.
              </h2>
              <h2>Make sure the data <a href="https://www.json.org/json-en.html">conforms to JSON specification</a>.</h2>

              <hr />

              <div>
                Not sure where to start?&nbsp;
                <a href="#chart" className="scrollto">Check out the description</a> or <a href="#" onClick={this.loadExample.bind(this)}>load an example</a>

              </div>
            </div>

            <div className="col-lg-6 order-2 order-lg-2 py-4">
              <div id="jsonInput">

                <textarea ref={(input) => { this.textArea = input; }} className={`form-control ${this.state.isValid === false ? 'is-invalid' : ''}`}
                  value={this.state.text} onChange={this.changeValue.bind(this)}></textarea>

                <div className="invalid-feedback">
                  Invalid JSON input. Please check the input format and make sure it is valid.
                </div>

                {/* {
                  this.state.isValid === null && this.state.text.length > 0 &&
                  <div>
                    Processing...
                    </div>
                } */}

                <a href="#chart" className={`go-to-chart-button scrollto ${this.canProceedToChart() ? '' : 'invisible'}`}>Proceed to chart <i className="icofont-simple-down"></i></a>
              </div>

            </div>
          </div>
        </div>

      </section >
    )
  }
}

export default JsonInputSection