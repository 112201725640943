
export class Debouncer {
    period: number;
    timeoutVar: any;

    constructor(durationMs: number) {
        this.period = durationMs;
    }

    run(action: any) {
        if (this.timeoutVar) {
            clearTimeout(this.timeoutVar);
            this.timeoutVar = undefined;
        }

        this.timeoutVar = setTimeout(() => {
            action();
            this.timeoutVar = undefined;
        }, this.period);
    }
}
