export const backgroundColors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
];

export const borderColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
];

export interface ChartType {
    id: number;
    name: string;
    icon: string;
    type: string;
    canBeStacked: boolean;
    xAxis: {
        name: string;
        types: string[];
    }
}

export const chartTypes: ChartType[] = [
    {
        id: 1,
        name: "Line",
        icon: 'fa-chart-line',
        type: "line",
        canBeStacked: true,
        xAxis: {
            name: 'X Axis Value',
            types: ['number', 'date', 'string'],
        }
    },
    {
        id: 2,
        name: 'Bar',
        icon: 'fa-chart-bar',
        type: 'bar',
        canBeStacked: true,
        xAxis: {
            name: 'X Axis Value',
            types: ['number', 'date', 'string'],
        }
    },
    {
        id: 3,
        name: "Scatter",
        icon: 'fa-braille',
        type: "scatter",
        canBeStacked: false,
        xAxis: {
            name: 'X Axis Value',
            types: ['number', 'date'],
        }
    },
    {
        id: 4,
        name: "Pie",
        icon: 'fa-chart-pie',
        type: "pie",
        canBeStacked: false,
        xAxis: {
            name: 'Category',
            types: ['string'],
        }
    }
];