import React, { Component } from 'react';
import { Chart } from 'chart.js';
import { appState } from '../AppState';

export class ChartWidget extends Component<any> {

  myChart: any;
  chartRef: React.RefObject<HTMLCanvasElement>;
  data: any;

  constructor(props: any) {
    super(props);

    this.chartRef = React.createRef();
    this.data = this.props.data;
  }

  refresh() {
    this.setState({});
  }

  componentDidUpdate() {
    if (this.myChart.type !== appState.selectedChartConfig.type) {
      this.myChart.destroy();
      this.recreateChart();
    } else {
      this.myChart.data = appState.getChartData();
      this.myChart.update();
    }
  }

  componentDidMount() {
    this.recreateChart();
  }

  private recreateChart() {
    var watermarkImage = new Image();
    watermarkImage.src = "/assets/img/logo.png";

    // this is important for exporting as image
    watermarkImage.crossOrigin = "Anonymous";

    this.myChart = new Chart(this.chartRef.current as HTMLCanvasElement, {
      type: appState.selectedChartConfig.type,
      options: {
        watermark: {
          image: watermarkImage,

          opacity: 0.2,

          alignX: "middle",
          alignY: "middle",

          alignToChartArea: false,

          position: "back",
        }
      } as any,
      data: appState.getChartData(),
    });
  }

  render() {
    return <canvas ref={this.chartRef} />;
  }
}
