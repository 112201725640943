import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase/app'
import 'firebase/analytics';

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

var firebaseConfig = {
  apiKey: "AIzaSyBXDUoAoLQbw4qtmsW3roCRrpzZMdqIP-E",
  authDomain: "jsontochart.firebaseapp.com",
  projectId: "jsontochart",
  storageBucket: "jsontochart.appspot.com",
  messagingSenderId: "923677325883",
  appId: "1:923677325883:web:2c8c019700d9d757f7ba1e",
  measurementId: "G-YBZ62HBNXP"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
