import { Component } from 'react';
import { chartTypes, ChartType } from '../chartConfig';
import { appState } from '../AppState';

export class ChartTypeSelector extends Component<any> {

  onTypeSelected(chartConfig: ChartType) {
    appState.selectedChartConfig = chartConfig;

    this.setState({});

    this.props.onChange();
  };

  render() {
    const chartTypeButtons = chartTypes.map((chartConfig) => {
      return <li className="nav-item" key={chartConfig.id}>
        <a className={`nav-link ${chartConfig.id === appState.selectedChartConfig.id ? 'active' : ''}`}
          onClick={() => this.onTypeSelected(chartConfig)}>
          <i className={`fas ${chartConfig.icon}`}></i> {chartConfig.name}</a>
      </li>;
    });

    return (
      <div className="chartTypeTabs">
        <ul className="nav nav-tabs" id="chartTypeTabs">
          {chartTypeButtons}
        </ul>
        <div className="tab-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
