import React from 'react';
import { Component } from 'react'
import { JsonPathPicker } from 'react-json-path-picker';
import { appState } from '../AppState';
import { Utils } from '../Utils';

interface FieldPickerProps {
    initialValue?: string;
    onChange: (path: string) => void;
    onRemove?: () => void;
}

export class FieldPicker extends Component<FieldPickerProps, {
    isListOpen: boolean;
    chosenPath: string;
}> {
    wrapperRef: any;

    constructor(props: FieldPickerProps) {
        super(props);

        this.wrapperRef = React.createRef();

        this.state = {
            isListOpen: false,
            chosenPath: props.initialValue ?? ''
        };
    }

    onChange(path: string) {

        this.setState({
            isListOpen: false,
            chosenPath: path
        });


        this.props.onChange(path);
    }

    toggleList = () => {
        this.setState({
            isListOpen: !this.state.isListOpen
        });

        return false;
    }

    componentDidUpdate() {
        if (this.state.isListOpen) {
            window.addEventListener('mousedown', this.close.bind(this))
        }
        else {
            window.removeEventListener('mousedown', this.close.bind(this))
        }
    }

    close(ev: MouseEvent) {
        if (!this.wrapperRef || !this.wrapperRef.current)
            return;

        if (!this.wrapperRef.current.contains(ev.target)) {
            this.setState({
                isListOpen: false,
            });
        }
    }

    getTitle(): string {
        if (this.state.chosenPath?.length > 0)
            return Utils.prettifyPath(this.state.chosenPath);

        return '<select>';
    }

    render() {
        const { isListOpen } = this.state;
        return (
            <div ref={this.wrapperRef} className="btn-group dropright btn-block">
                <button type="button" className="btn btn-light btn-sm btn-block" aria-haspopup="true"
                    aria-expanded="false" onClick={this.toggleList.bind(this)}>
                    {this.getTitle()}&nbsp;&nbsp;
                </button>
                {this.props.onRemove &&
                    <button type="button" className="btn btn-light btn-sm dropdown-toggle-split"
                        onClick={this.props.onRemove}>
                        <span className="fas fa-times red"></span>
                    </button>
                }
                {
                    isListOpen &&
                    <div className="dropdown-menu picker-popup show" aria-labelledby="dropdownMenuButton">
                        {
                            (appState.jsonSample ?? '').length > 0 &&
                            <JsonPathPicker json={appState.jsonSample} onChoose={this.onChange.bind(this)} path={this.state.chosenPath} />
                        }
                        {
                            (appState.jsonSample ?? '').length === 0 &&
                            <span>No JSON data found</span>
                        }
                    </div>
                }
            </div>
        )

        // return (
        //     <div className="dropdown dropright">
        //         <button className="btn btn-secondary btn-sm btn-block" type="button" data-toggle="dropdown" aria-haspopup="true"
        //             aria-expanded="false">
        //             {this.getTitle()}&nbsp;&nbsp;
        //         </button>
        //         {this.props.onRemove &&
        //             <button className="btn btn-danger btn-sm" onClick={this.props.onRemove}><span className="fas fa-times"></span></button>
        //         }
        //         <div className="dropdown-menu picker-popup" aria-labelledby="dropdownMenuButton">
        //             <JsonPathPicker json={appState.jsonSample} onChoose={this.onChange.bind(this)} path={this.state.chosenPath} />
        //         </div>
        //     </div>
        // )
    }

}