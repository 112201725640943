export class Notifier {
    observers = Array<any>();

    subscribe(observer: any) {
        this.observers.push(observer);
    }

    notify() {
        console.debug('Notifying ' + this.observers.length + ' observers');

        this.observers.forEach((observer) => {
            observer();
        });
    }
}