import { Component } from 'react'

import { appState } from '../AppState';
import 'chartjs-plugin-watermark'
import { XAxisFieldPicker } from './XAxisFieldPicker';
import { ValuesPicker } from './ValuesPicker';
import { ChartTypeSelector } from './ChartTypeSelector';
import { ChartWidget } from './ChartWidget';

import firebase from 'firebase/app'

export class ChartSection extends Component {
  chartWidget: ChartWidget | null | undefined;

  componentDidMount() {
    // refresh chart when data is updated
    //TODO: move to chart and remove references?
    appState.dataChangeNotifier.subscribe(() => {
      if (this.chartWidget !== undefined && this.chartWidget !== null) {
        this.chartWidget.refresh();

        firebase.analytics().logEvent('chart_generated', {
          'type': appState.selectedChartConfig.type,
          'record_count': appState.jsonData.length
        });
      }
    });
  }

  chartTypeChanged() {
    // might want to refactor to set state here and let refresh by observer

    if (this.chartWidget !== undefined && this.chartWidget !== null) {
      this.chartWidget.refresh();

      firebase.analytics().logEvent('chart_type_changed', {
        'type': appState.selectedChartConfig.type
      });
    }
  }

  download() {
    if (this.chartWidget?.chartRef?.current) {

      const resizedCanvas = document.createElement("canvas");
      const resizedContext = resizedCanvas.getContext("2d");

      const originalCanvas = this.chartWidget.chartRef.current;

      const width = Math.max(originalCanvas.width, 1500);
      const height = originalCanvas.height * (width / originalCanvas.width);

      resizedCanvas.width = width;
      resizedCanvas.height = height;

      resizedContext?.drawImage(this.chartWidget.chartRef.current, 0, 0, width, height);

      var url = resizedCanvas.toDataURL("image/png", 1);

      var link = document.createElement('a');
      link.download = 'jsonchart.png';
      link.href = url;
      link.click();

      firebase.analytics().logEvent('download_chart');
    }
  }

  render() {
    return (
      <section id="chart" className="chart section-bg d-flex align-items-center">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="row">

                <div className="col-lg-3">

                  <div className="card">
                    <div className="card-header">Data</div>
                    <div className="card-body">
                      <div className="form-group">
                        <label className="">Select X Axis field</label>
                        <XAxisFieldPicker initialValue={appState.state.xAxisFieldPath} onChange={(path) => appState.setXAxisPath(path)} />

                        <hr />

                        <label>Select value fields</label>
                        <ValuesPicker initialValues={appState.state.valuesFieldPaths} onChange={(values) => appState.setValues(values)} />

                      </div>
                    </div>
                  </div>

                  <button className="btn btn-custom btn-block" onClick={this.download.bind(this)}><i className="fas fa-file-download"></i> Download as image</button>

                </div>

                <div id="chartContainer" className="col-lg-9">
                  <ChartTypeSelector onChange={this.chartTypeChanged.bind(this)}>
                    <div className="col">
                      {/* Options */}
                    </div>

                    <ChartWidget ref={widget => this.chartWidget = widget} />
                  </ChartTypeSelector>
                </div>

              </div>


            </div>

          </div>

        </div>
      </section >
    )
  }
}

export default ChartSection