import { backgroundColors, borderColors, chartTypes, ChartType } from './chartConfig'
import { Notifier } from './Notifier';

import jp from 'jsonpath';
import { Utils } from './Utils';

export interface ChartState {
    xAxisFieldPath: string;
    valuesFieldPaths: string[];
}

class AppState {

    dataChangeNotifier: Notifier = new Notifier();
    structureChangeNotifier: Notifier = new Notifier();

    selectedChartConfig: ChartType = chartTypes[0];

    jsonText: string = '';
    jsonSample: string = '';

    jsonData: Array<any> = [];

    state: ChartState = {
        xAxisFieldPath: '',
        valuesFieldPaths: ['']
    };

    isStructureDifferent(previousSample: string, currentSampleObject: any): boolean {

        //const previousSampleObject = JSON.parse(previousSample);

        //TODO: check if structure is the same (compare JSON paths) and clear only then

        return false;
    }

    loadExample(exampleJson: string, exampleState: ChartState) {
        const isValid = this.setJson(exampleJson);

        if (isValid) {
            this.state = exampleState;
        }

        this.structureChangeNotifier.notify();
        this.dataChangeNotifier.notify();

        return isValid;
    }

    setJson(value: string): boolean {

        try {

            if (value) {
                var result = JSON.parse(value);

                if (Array.isArray(result)) {
                    if (this.isStructureDifferent(this.jsonSample, result[0])) {
                        this.clear();
                    }
                } else {
                    // it's a single object
                    //TODO: map attributes to columns
                    result = [result];
                }

                this.jsonText = value;
                this.jsonSample = JSON.stringify(result[0]);
                this.jsonData = result;

                this.dataChangeNotifier.notify();

            } else {
                this.clear();
            }

            return true;
        } catch (e) {
            console.debug(e);
            return false;
        }
    }

    clear() {
        this.state.xAxisFieldPath = '';
        this.state.valuesFieldPaths = [''];

        this.jsonText = '';
        this.jsonSample = '';
        this.jsonData = [];

        this.structureChangeNotifier.notify();
        this.dataChangeNotifier.notify();
    }

    setXAxisPath(path: string) {
        this.state.xAxisFieldPath = path;
        console.debug('X axis changed to: ' + path);

        this.dataChangeNotifier.notify();
    }

    setValues(values: string[]): void {
        this.state.valuesFieldPaths = values;
        console.debug('Value list changed to: ' + JSON.stringify(values));

        this.dataChangeNotifier.notify();
    }

    changeChartType(chartConfig: ChartType) {
        this.selectedChartConfig = chartConfig;
        console.debug('Chart type changed to: ' + chartConfig.type);

        this.dataChangeNotifier.notify();
    }

    getChartData(): any {
        var chartData = {
            labels: [] as string[],
            datasets: [
            ] as Array<any>
        };

        if (this.jsonData) {
            for (let index = 0; index < this.state.valuesFieldPaths.length; index++) {
                const valueFieldPath = this.state.valuesFieldPaths[index];

                if (valueFieldPath.length > 0) {
                    var newDataSet = {
                        backgroundColor: this.scrambleArray(backgroundColors, index % backgroundColors.length),
                        borderColor: this.scrambleArray(borderColors, index % borderColors.length),
                        label: Utils.prettifyPath(valueFieldPath),
                        data: []
                    };

                    chartData.labels = this.fillDataSet(newDataSet, this.jsonData, this.state.xAxisFieldPath, valueFieldPath);
                    chartData.datasets.push(newDataSet);
                }
            };
        }

        return chartData;
    }

    fillDataSet(dataset: any, data: Array<any>, xAxisFieldPath: string, valueFieldPath: string): string[] {

        var shouldUseLabels: boolean | null = null;
        var labels = Array<string>();

        if (xAxisFieldPath.length > 0 && valueFieldPath.length > 0) {

            data.forEach((dataItem) => {

                var xFieldValue = jp.value(dataItem, '$' + xAxisFieldPath.replaceAll('"', ''));
                var yFieldValue = jp.value(dataItem, '$' + valueFieldPath.replaceAll('"', ''));

                shouldUseLabels = shouldUseLabels ?? (isNaN(xFieldValue));

                if (shouldUseLabels) {
                    labels.push(xFieldValue);
                    dataset.data.push(yFieldValue);
                }
                else {
                    dataset.data.push({ x: xFieldValue, y: yFieldValue });
                }
            });
        }

        return labels;
    };

    scrambleArray(array: Array<any>, startFrom: number) {

        let newArray = array.slice();

        if (startFrom === 0) return newArray;

        var firstPart = newArray.splice(startFrom + 1);
        var secondPart = newArray.splice(0, startFrom);

        return firstPart.concat(secondPart);
    }

}

// global state
export var appState = new AppState();