import { Component } from 'react';
import { appState } from '../AppState';
import { FieldPicker } from './FieldPicker';

interface ValuesPickerProps {
  initialValues?: string[];
  onChange: (path: string[]) => void;
}

export class ValuesPicker extends Component<ValuesPickerProps, {
  values: {
    // using this to have component keys for deletion
    id: number;
    value: string;
  }[];
}> {

  static counter = 1;

  constructor(props: ValuesPickerProps) {
    super(props);

    const initial = this.props.initialValues ?? [''];
    this.state = {
      values: initial.map((val) => { return { id: ValuesPicker.counter++, value: val } })
    };
  }

  addNewValue() {
    this.setState({ values: [...this.state.values, { id: ValuesPicker.counter++, value: '' }] });
  }

  componentDidMount() {
    appState.structureChangeNotifier.subscribe(() => {

      // reset if data structure changes
      this.setState({
        values: appState.state.valuesFieldPaths.map((value) => ({ id: ValuesPicker.counter++, value: value }))
      });
    });
  }

  changeValue(index: number, path: string) {
    this.state.values[index].value = path;

    this.props.onChange(this.state.values.map((val) => val.value));
  }

  remove(index: number) {
    var newArray = [...this.state.values];
    newArray.splice(index, 1);

    this.setState({
      values: newArray
    });

    this.props.onChange(newArray.map((val) => val.value));
  }

  render() {
    const valuePickers = this.state.values.map((item, i) => {
      var onRemove = this.state.values.length > 1 ? () => this.remove(i) : undefined;
      return (
        <FieldPicker key={item.id} initialValue={item.value} onChange={(path) => this.changeValue(i, path)}
          onRemove={onRemove} />
      );
    });

    return (
      <div>
        {valuePickers}
        <div>
          <br />
          <button className="btn btn-custom btn-sm" onClick={this.addNewValue.bind(this)}><i className="fas fa-plus"></i> Add value</button>
        </div>
      </div>
    );
  }
}
