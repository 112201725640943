import { JsonInputSection } from './JsonInputSection'
import { ChartSection } from './chart/ChartSection'

function App() {
  return (
    <div>
      <JsonInputSection />
      <ChartSection />
    </div>
  );
}

export default App;